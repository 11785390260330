import React from 'react';
import Footer from '../components/Footer/Footer';
import { IHeaderLayoutProps } from '../pages';

const HeaderLayout = ({
  component: Component,
  headerContent,

}: IHeaderLayoutProps) => (
  <div>
    <nav
      className="nav"
    >
      { headerContent }
    </nav>
    <div className="navbar-padding">
      <Component />
    </div>
    <Footer />
  </div>
);

HeaderLayout.defaultProps = {
  transparent: false,
};

export default HeaderLayout;
