import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Empty, Typography } from 'antd';
import Spin from '../../components/Spin/Spin';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import Cards from '../../components/Cards';
import { imgURLPrefix } from '../../config/image';

const {
  Title,
} = Typography;
const SearchProducts = observer(() => {
  const { searchStore } = stores;
  const history = useHistory();

  useEffect(() => {
    searchStore.toggleShowSearchPanel(false);
  }, [searchStore.searchQuery, searchStore.loadSearchResults]);

  const resultsData = searchStore.searchResult.value.data;

  return (
    <main id="home">
      <Helmet>
        <title>Searched Products - G. Fox</title>
      </Helmet>

      <div className={styles.productShowcaseCon}>
        {
      resultsData.length > 0 && (
      <Title
        className={styles.productHeader}
        level={2}
      >
        {resultsData.length}
        {' '}
        Results for:
        {' '}
        {searchStore.searchQuery ?? ''}
      </Title>
      )
    }
        <div className={styles.productResultCardCon}>
          {searchStore.searchResult.isLoading ? (
            <div className={styles.searchSpinCon}>
              <Spin tip="Searching..." />
            </div>
          ) : (
            React.Children.toArray(
              resultsData.map((item: any) => (
                item.masterStock && (
                  <Cards.ProductCard
                    className={styles.productResultCard}
                    color="red"
                    imageSrc={`${imgURLPrefix}${item.subCategoryLongImage}`}
                    imgClassName={styles.productResultImg}
                    productName={item.productName ?? ''}
                    pricing={{
                      masterStock: item.masterStock,
                      accountPricing: item.accountPricing,
                    }}
                    shopLink={`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`}
                    productNameClassName={styles.productResultPName}
                    optType="viewBtn"
                    removeBg
                    separator
                    viewLink={`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`}
                    onViewLinkClick={(e) => {
                      e.preventDefault();
                      // eslint-disable-next-line max-len
                      history.push(`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`);
                    }}
                  />
                )
              )),
            )
          )}
          {resultsData.length === 0 && !searchStore.searchResult.isLoading && (
          <Empty
            className={styles.emptyList}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
          )}
        </div>

      </div>
    </main>
  );
});

export default SearchProducts;
