import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Empty, Typography } from 'antd';
import Spin from '../../components/Spin/Spin';
import styles from './styles.module.css';
import stores from '../../stores/stores';
import Cards from '../../components/Cards';
import { imgURLPrefix } from '../../config/image';
import { useAuthContext } from '../../context/AuthContext';

const {
  Title,
} = Typography;
const FavoriteProducts = observer(() => {
  const { clientStore } = stores;
  const { currentClient } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    if (currentClient) {
      clientStore.fetchFavorites(currentClient.id);
    }
  }, [currentClient]);

  const resultsData = clientStore.favoriteProducts;

  return (
    <main id="home">
      <Helmet>
        <title>Favorite products - G. Fox</title>
      </Helmet>

      <div className={styles.productShowcaseCon}>
        <Title
          className={styles.productHeader}
          level={2}
        >
          My favorite products:
        </Title>
        <div className={styles.productResultCardCon}>
          {clientStore.isLoadingFavorites ? (
            <div className={styles.searchSpinCon}>
              <Spin tip="Loading..." />
            </div>
          ) : (
            React.Children.toArray(
              resultsData.map((item: any) => (
                item.sku && (
                  <Cards.ProductCard
                    className={styles.productResultCard}
                    color="red"
                    imageSrc={`${imgURLPrefix}${item.subCategoryLongImage}`}
                    imgClassName={styles.productResultImg}
                    productName={item.productName ?? ''}
                    pricing={{
                      masterStock: item.masterStock,
                      accountPricing: item.accountPricing,
                    }}
                    shopLink={`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`}
                    productNameClassName={styles.productResultPName}
                    optType="viewBtn"
                    removeBg
                    separator
                    viewLink={`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`}
                    onViewLinkClick={(e) => {
                      e.preventDefault();
                      // eslint-disable-next-line max-len
                      history.push(`/products/category/sub-category/${item.sku}?stockCode=${item.masterStock?.stockCode ? item.inventoryMasterSku : ''}`);
                    }}
                  />
                )
              )),
            )
          )}
          {resultsData.length === 0 && !clientStore.isLoadingFavorites && (
          <Empty
            className={styles.emptyList}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
          )}
        </div>

      </div>
    </main>
  );
});

export default FavoriteProducts;
