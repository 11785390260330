import React, { ReactNode } from 'react';
import Typography from '../Typography';
import styles from './styles.module.css';

export interface ITransparentBgImageOpts {
    mobileScreen?: string,
    tabletScreen?: string,
    laptopScreen?: string,
    /* used as fallback image */
    largeScreen?: string,
}

interface ITransparentBgImageProps {
  itemClassName?: string;
  backgroundImage?: ITransparentBgImageOpts
  extraNode?: ReactNode;
  headerText?: ReactNode;
  subHeaderText?: ReactNode;
  subText?: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  onClick?: () => void,
  position?: 'left' | 'right' | 'center';
  overlay?: boolean,
}

const { Title } = Typography;

// const backgroundImageStyle = (backgroundImage: string, overlay: boolean): string => {
//   let style = '';
//   if (overlay) {
//     style += ;
//   }
//   return `${style} url(${backgroundImage})`;
// };

const TransparentBgImage = ({
  itemClassName,
  backgroundImage,
  extraNode,
  headerText,
  subHeaderText,
  subText,
  position,
  overlay,
}: ITransparentBgImageProps) => (
  <div>

    {backgroundImage && (
      <picture className={[styles.carouselBackground, itemClassName].join(' ')}>
        <source srcSet={backgroundImage.mobileScreen} media="(max-width: 688px)" />
        <source srcSet={backgroundImage.tabletScreen} media="(max-width: 992px)" />
        <source srcSet={backgroundImage.laptopScreen} media="(max-width: 1312px)" />

        <img src={backgroundImage.largeScreen} alt="banner" />
      </picture>
    )}

    <div
      className={[
        styles.carouselItemContainer,
        position === 'left' && styles.carouselItemContainerLeft,
        itemClassName,
      ].join(' ')}
      style={{
        backgroundColor: overlay ? 'rgba(0, 0, 0, 0.5) ' : 'none',
      }}
    >
      <div className={styles.carouselTextContainer}>
        <Title
          level={2}
          className={styles.carouselSubHeaderText}
        >
          {subHeaderText ?? ''}
        </Title>
        <Title
          level={1}
          className={styles.carouselHeaderText}
        >
          {headerText}
        </Title>

        {subText}
      </div>
      {extraNode}
    </div>
  </div>
);

TransparentBgImage.defaultProps = {
  itemClassName: undefined,
  backgroundImage: undefined,
  extraNode: undefined,
  headerText: undefined,
  subHeaderText: undefined,
  subText: undefined,
  position: 'right',
  overlay: false,
};

export default TransparentBgImage;
