import APIClient, {
  IOrderMessage,
  LoadManager,
} from '@bridgelabsdesign/gfox-api-client';
import {
  action, makeObservable, observable,
} from 'mobx';
import { message, notification } from 'antd';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { APP_URL_HOST } from '../config/websocket';
import { formatDate, isEmptyString } from '../utils/strings';
import { NotificationSound } from '../assets';

class ChatStore {
    orderMessages = new LoadManager<IOrderMessage>({ data: [] }, APIClient.OrderMessages.getOrderMessages, APIClient.OrderMessages.addOrderMessage);

    unreadMessages: IOrderMessage[] = []

    messageConnection?: HubConnection;

    currentOrderId?: string;

    messages = ''

    constructor() {
      makeObservable(this, {
        orderMessages: observable,
        fetchOrderMessages: action,
        postMessage: action,
        reset: action,
        setMessage: action,
        messages: observable,
        startMessageConnection: action,
        unreadMessages: observable,
      });
    }

    setMessage(s:string) {
      this.messages = s;
    }

    // eslint-disable-next-line class-methods-use-this
    buildHubConnection(clientId: string | undefined, authKey: string, path: string): HubConnection {
      return new HubConnectionBuilder()
        .withUrl(`${APP_URL_HOST}hub/${path}?clientId=${clientId ?? ''}`, {
          headers: { Authorization: `Basic ${authKey}` },
        })
        .withAutomaticReconnect()
        .build();
    }

    async startMessageConnection(clientId: string | undefined, authKey: string) {
      const messageConnection = this.buildHubConnection(clientId, authKey, 'message');
      // eslint-disable-next-line no-unused-vars
      messageConnection.on('DirectMessage', async (orderMessage, ...args) => {
        const receivedMessage = JSON.parse(orderMessage) as IOrderMessage;
        this.unreadMessages.push(receivedMessage);
        notification.info({
          key: clientId,
          message: 'New Order Message',
          icon: React.createElement(MessageOutlined),
          description: receivedMessage.message,
          className: 'message-notification',
        });

        const audio = new Audio(NotificationSound);
        audio.play().catch(() => {
          audio.muted = true;
          audio.play().catch(() => { });
        });
        if (this.currentOrderId === receivedMessage.orderId) {
          this.fetchOrderMessages(this.currentOrderId);
        }
      });
      messageConnection.on('QuoteGenerated', async (quoteData) => {
        const receivedQuote = JSON.parse(quoteData); // Assuming IQuote interface is applicable here

        notification.info({
          message: receivedQuote?.refNo ? `#${receivedQuote?.refNo} Ready` : 'Quote Ready',
          description: formatDate(receivedQuote?.createdAt as string, true),
          duration: 5,
          onClick: () => {
            window.open(receivedQuote?.fileUrl, '_blank'); // navigate to the URL in a new tab
          },
          className: 'quote-notification',
        });

        const audio = new Audio(NotificationSound);
        audio.play().catch(() => {
          audio.muted = true;
          audio.play().catch(() => { });
        });
      });

      try {
        await messageConnection.start();
      } catch (error) {
        console.log(error);
      }
    }

    async fetchOrderMessages(orderId:string) {
      this.currentOrderId = orderId;
      await this.orderMessages.fetch(`orderId=${orderId}`);
      if (this.orderMessages.error) {
        message.error('Could not load messages.');
      }
    }

    async postMessage(fromClientId:string, orderId:string) {
      // eslint-disable-next-line @typescript-eslint/no-shadow, prefer-destructuring
      const messages = this.messages;
      if (isEmptyString(messages)) {
        return;
      }

      message.loading('Sending message...');
      const body = { fromClientId, orderId, message: messages } as unknown as IOrderMessage;
      await this.orderMessages.add(body);
      if (this.orderMessages.error) {
        message.error('Could not load messages.');
        return;
      }
      this.setMessage('');
      message.destroy();
    }

    reset() {
      this.orderMessages.value.data = [];
      this.messages = '';
    }
}

export default ChatStore;
