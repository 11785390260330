import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { IoClose } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import { Alert } from 'antd';
import mixpanel from 'mixpanel-browser';
import Spin from '../Spin/Spin';
import styles from './search-results.module.css';
import stores from '../../stores/stores';
import Form from '../Form';
import Buttons from '../Buttons';
import Typography from '../Typography';
import PricingInformation from '../Pricing/PricingInformation';
import { imgURLPrefix } from '../../config/image';

const { Text } = Typography;

const SearchResults = observer(() => {
  const { searchStore, cartStore } = stores;

  const history = useHistory();

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchStore.setSearchQuery(e.target.value);
  };

  const handleShowPanel = (value?: boolean) => {
    searchStore.toggleShowSearchPanel(value);
  };

  const handleSearchItemClick = (sku: string, inventoryMasterSku?: string | null) => {
    cartStore.clearSelectedProductItems();
    const params = inventoryMasterSku ? `stockCode=${inventoryMasterSku}` : '';
    history.push(`/products/category/sub-category/${sku}?${params}`);
    searchStore.resetSearch();

    try {
      mixpanel.track('Product Search Click', {
        sku: inventoryMasterSku,
        parentSku: sku,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const resultsData = searchStore.searchResult.value.data;

  useEffect(() => {
    const unListen = history.listen(() => {
      handleShowPanel(false);
    });
    return () => {
      unListen();
    };
  }, []);

  return (
    <div className={[styles.con].join(' ')}>
      <div className={styles.contentCon}>
        <Buttons.PrimaryBtn
          className={styles.searchCloseBtn}
          icon={(
            <IoClose
              className={styles.searchCloseIcon}
            />
          )}
          onClick={() => handleShowPanel()}
          type="text"
        />

        <div className={styles.searchInputCon}>
          <Form.SearchInput
            placeholder="Search products"
            onChange={handleSearchInput}
            className={styles.searchInput}
            size="large"
            value={searchStore.searchQuery}
          />
        </div>

        {searchStore.showErrorPanel && (
          <div className={styles.searchSpinCon}>
            <Alert
              afterClose={() => handleShowPanel(false)}
              closable
              message="No results found."
              showIcon
              type="error"
            />
          </div>
        )}

        {searchStore.searchResult.isLoading && (
          <div className={styles.searchSpinCon}>
            <Spin tip="Searching..." />
          </div>
        )}
        {searchStore.showResultsPanel && (
          <div className={styles.searchItemsCon}>
            <div className={styles.viewAllButtonContainer}>
              <Buttons.LinkBtn
                type="text"
                link={{
                  href: `/products?search=${searchStore.searchQuery}`,
                }}
              >
                View all
              </Buttons.LinkBtn>
            </div>
            {resultsData.map(((item) => item.masterStock && (
              <div
                key={item.sku}
                className={styles.searchItem}
                onClick={() => handleSearchItemClick(item.sku, item.masterStock?.stockCode)}
                aria-hidden
              >
                <div className={styles.searchItemImg}>
                  <img
                    src={`${imgURLPrefix}${item.subCategoryLongImage}`}
                    alt="Preview"
                  />
                </div>

                <div className={styles.searchItemInfo}>
                  <Text className={styles.searchItemName}>
                    {item.productName}
                  </Text>
                  <PricingInformation
                    textClassName={styles.searchItemPriceText}
                    text2ClassName={styles.searchItemPriceText2}
                    pricing={{
                      masterStock: item.masterStock,
                      accountPricing: item.accountPricing,
                    }}
                  />
                </div>

                {!item.active && (
                  <Text className={styles.searchItemInactive}>
                    Coming soon
                  </Text>
                )}
              </div>
            )))}
          </div>
        )}
      </div>
    </div>
  );
});

export default SearchResults;
