/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */

export enum LegalDocuments {
  // eslint-disable-next-line no-unused-vars
  TRADING_TERMS = 'https://gfox-cdn.bridgelabs.design/documents/legal/GFox-Terms-and-Conditions.pdf',
  // eslint-disable-next-line no-unused-vars
  RETURN_POLICY = 'https://gfox-cdn.bridgelabs.design/documents/legal/GFox-Returns-Policy.pdf',
  // eslint-disable-next-line no-unused-vars
  CREDIT_APPLICATION = 'https://gfox-cdn.bridgelabs.design/documents/legal/g-fox-credit-application.pdf',
  // eslint-disable-next-line no-unused-vars
  PRIVACY_POLICY = 'https://gfox-cdn.bridgelabs.design/documents/legal/GFox-POPIA-Processing-Notice-26012022.pdf',
  // eslint-disable-next-line no-unused-vars
  GROUP_PAIA_MANUAL = 'https://gfox-cdn.bridgelabs.design/documents/legal/the-bidvest-group-limited-paia-manual-2024.pdf',
  // eslint-disable-next-line no-unused-vars
  COOKIE_POLICY = 'https://gfox-cdn.bridgelabs.design/documents/legal/GFox-Cookie-Notice-26012022.pdf',
  // eslint-disable-next-line no-unused-vars
  EXTERNAL_PROCESSING_NOTICE = 'https://gfox-cdn.bridgelabs.design/documents/legal/POPIA-Processing-Notice-External-2021.pdf',
  // eslint-disable-next-line no-unused-vars
  HR_PROCESSING_NOTICE = 'https://gfox-cdn.bridgelabs.design/documents/legal/POPIA-Processing-Notice-HR-2021.pdf',
  // eslint-disable-next-line no-unused-vars
  CCTV_CAMERA_POLICY = 'https://gfox-cdn.bridgelabs.design/documents/legal/G-FOX-CCTV-Camera-Policy.pdf',

    // eslint-disable-next-line no-unused-vars
  OBJECTION_TO_PROCESSING = 'https://gfox-cdn.bridgelabs.design/documents/legal/Objection to the processing of personal information.pdf',
  // eslint-disable-next-line no-unused-vars
  POPIA_COMPLAINT_FORM = 'https://gfox-cdn.bridgelabs.design/documents/legal/POPIA complaint form.pdf',
  // eslint-disable-next-line no-unused-vars
  POPIA_INTERNAL_COMPLAINT_FORM = 'https://gfox-cdn.bridgelabs.design/documents/legal/POPIA internal complaint form.pdf',
  // eslint-disable-next-line max-len
  REQUEST_FOR_CORRECTION = 'https://gfox-cdn.bridgelabs.design/documents/legal/Request for correction or deletion of personal information or destruction or deletion of record of personal information..pdf',
  // eslint-disable-next-line max-len
  REQUEST_FOR_CONSENT = 'https://gfox-cdn.bridgelabs.design/documents/legal/Request for data subjects consent to process personal information.pdf',
}
