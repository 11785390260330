import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import html2canvas from 'html2canvas';
import LoyaltyCard from '../../components/Cards/LoyaltyCard';
import Typography from '../../components/Typography';
import styles from './styles.module.css';
import Images from '../../assets';
import AllocateLoyaltyCard from '../../components/Cards/AllocateLoyaltyCard';
import { useAuthContext } from '../../context/AuthContext';
import { isEmptyString } from '../../utils/strings';
import GFoxCard from '../../components/Cards/LoyaltyCardFront';
import Buttons from '../../components/Buttons';

const { Title } = Typography;

const AccountLoyaltyCard = observer(() => {
  const { currentClient } = useAuthContext();
  const loyaltyCardRef = useRef<HTMLDivElement>(null);

  const downloadCardAsImage = async () => {
    if (loyaltyCardRef.current) {
      const canvas = await html2canvas(loyaltyCardRef.current);
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'loyalty-card.png';
      link.click();
    }
  };

  return (
    <main id="account-loyalty-card">
      <Helmet>
        <title>Loyalty Card - G. Fox</title>
      </Helmet>

      <Title className={styles.welcomeText} level={3}>
        My Loyalty Card
      </Title>
      <div className={styles.searchDatePickerContainer}>
        <Buttons.SecondaryBtn
          className={styles.unverifiedBtn}
          type="primary"
          onClick={downloadCardAsImage}
        >
          Download Card as Image
        </Buttons.SecondaryBtn>
      </div>
      <div>
        {!isEmptyString(currentClient?.loyaltyCardNo) ? (
          <div className={styles.cardCon}>
            <GFoxCard />
            <LoyaltyCard
              ref={loyaltyCardRef}
              cardNumber={currentClient?.loyaltyCardNo}
              imageUrl={Images.GFoxLogo}
            />
          </div>
        ) : (
          <AllocateLoyaltyCard />
        )}
      </div>
    </main>
  );
});

export default AccountLoyaltyCard;
