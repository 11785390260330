/* eslint-disable max-len */
import React from 'react';
import {
  Drawer, Button, Input, Radio, Select, message, Upload,
} from 'antd';
import {
  ISurvey, ISurveyQuestion, ISurveyResponseObject,
} from '@bridgelabsdesign/gfox-api-client';
import { AiFillCloseCircle } from 'react-icons/ai';
import { UploadOutlined } from '@ant-design/icons';
import { SurveyQuestionTypeId } from '@bridgelabsdesign/gfox-api-client/dist/utils/enums';
import { observer } from 'mobx-react-lite';
// eslint-disable-next-line camelcase
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import completedSurvey from '../../assets/illustrations/completed-survey.svg';

import './style.less';
import stores from '../../stores/stores';
import Spin from '../Spin/Spin';

type DrawerProps = {
    visible: boolean
    setVisible: Function,
    reviewsList: any[]
    selectedReview: any,
}
// Close button should be more visible

const ReviewDrawer = observer(({
  visible, setVisible, reviewsList, selectedReview,
}: DrawerProps) => {
  const [clickedItemInx, setClickedItemInx] = React.useState(0);
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [survey, setSurvey] = React.useState<ISurvey | undefined>();
  const [clickedReview, setClickedReview] = React.useState<ISurvey | null>(null);
  const [setRadioValue] = React.useState<any>([]);
  const [textKey, setTextKey] = React.useState(Math.random());
  const [setTextKeyDel] = React.useState<any>(Math.random());
  const [setRateKey] = React.useState<any>(Math.random());
  const [setRateKeyDel] = React.useState<any>(Math.random());
  const [selectKey, setSelectKey] = React.useState<any>(Math.random());
  const [setRadioKey] = React.useState<any>(Math.random());
  const [responseData, setResponseData] = React.useState<ISurveyResponseObject[]>([]);
  const [isPosting, setIsPosting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState('');
  const [selectedOpeningMsg, setSelectedOpeningMsg] = React.useState<string | null>(null);
  const [selectedClosingMsg, setSelectedClosingMsg] = React.useState<string | null>(null);

  const { TextArea } = Input;

  const onClose = () => {
    setVisible(false);
  };
  const { surveyStore, surveyResponseStore } = stores;

  const getSurvey = async (review:ISurvey) => {
    setLoading(true);
    setLoadingText('Getting Questions');
    const result = await surveyStore.loadSurveyById(review.id);
    console.log(result);
    setSurvey(result);
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const addQuestionResponses = async (responseValuesArray: ISurveyResponseObject[], survey:ISurvey) => {
    const formData = new FormData();
    // Append survey ID or any other necessary data
    formData.append('surveyId', survey.id);
    formData.append('response', JSON.stringify(responseValuesArray));
    fileList.forEach((file) => {
      formData.append('file', file.originFileObj as any);
    });
    setIsPosting(true);
    try {
    // Use your custom method to make the POST request
      await surveyResponseStore.postSurveyResponse(formData, []);
      // Handle the response as needed
      setIsPosting(false);
    } catch (error) {
      console.error(error);
    // Handle the error as needed
    }
  };

  const respondToQuestion = () => {
    const keys = Object.keys(responseData);
    const values = keys.map((v:any) => responseData[v]);
    console.log('respnse', responseData);
    console.log('values', values);
    // console.log(values, questionsWithoutHeaders)
    if (values.length !== survey?.surveyQuestions.length) {
      console.log('Tester');
      message.error('please answer every question on the survey!');
    } else if (clickedReview !== null) {
      // const surveyResponse: ISurveyResponse = {
      //   surveyId: clickedReview.id,
      //   response: values,
      // }as ISurveyResponse;
      const formData = new FormData();
      formData.append('surveyId', clickedReview.id);
      formData.append('response', JSON.stringify(values));
      fileList.forEach((file) => {
        formData.append('file', file.originFileObj as any);
      });

      const hide = message.loading('Adding your response', 0);
      setIsPosting(true);
      try {
        // surveyResponseStore.postSurveyResponse(formData, []);
        setRateKey(Math.random());
        setRateKeyDel(Math.random());
        setSelectKey(Math.random());
        setRadioKey(Math.random());
        setTextKey(Math.random());
        setTextKeyDel(Math.random());
        // eslint-disable-next-line no-param-reassign
        reviewsList[clickedItemInx].completed = true;
        const oldClickedItemInx = clickedItemInx;
        if (oldClickedItemInx < reviewsList.length - 1) {
          setClickedItemInx(oldClickedItemInx + 1);
          setClickedReview(reviewsList[oldClickedItemInx + 1]);

          reviewsList.splice(oldClickedItemInx);
          setIsPosting(false);
        } else if (reviewsList.length > 1) {
          setClickedItemInx(0);
          setClickedReview(reviewsList[0]);

          reviewsList.splice(oldClickedItemInx);
          setIsPosting(false);
        } else {
          // completed
        }
      } catch (error) {
        hide();
        setIsPosting(false);
        console.log(error);
      }
      addQuestionResponses(values, survey as unknown as ISurvey);
    }
  };

  React.useEffect(() => {
    if (selectedReview) {
      console.log(selectedReview);
      // let rList = moveItemToFront(selectedReview, reviewsList)
      setClickedItemInx(reviewsList.map((x) => x.id).indexOf(selectedReview.id));
      // setReviewListDisplay(rList)
      setClickedReview(selectedReview);
    }
  }, [selectedReview]);

  React.useEffect(() => {
    console.log('Drawer test');
    if (clickedReview) {
      // console.log(clickedEmp)
      getSurvey(clickedReview);
    }
  }, [clickedReview]);
  const onRadioChange = (e:any, q: ISurveyQuestion) => {
    // console.log('radio checked', e.target.value);

    setRadioValue(e.target.value);
    const responseArr = [];
    responseArr.push(e.target.value);
    const tempResponse = { ...responseData };
    const index = tempResponse.findIndex((x) => x.questionId === q.id);
    if (index > 0) {
      tempResponse[index] = {
        questionId: q.id,
        value: responseArr,
      };
    } else {
      tempResponse.push({
        questionId: q.id,
        value: responseArr,
      });
      setResponseData(tempResponse);
    }
  };

  const onSelectChange = (e:any, q:ISurveyQuestion) => {
    const tempResponse = [...responseData];
    const index = tempResponse.findIndex((x) => x.questionId === q.id);
    if (index > 0) {
      tempResponse[index] = {
        questionId: q.id,
        value: e,
      };
    } else {
      tempResponse.push(
        tempResponse[index] = {
          questionId: q.id,
          value: e,
        },
      );
    }
    // console.log(tempResponse)
    setResponseData(tempResponse);
  };

  const onUploadChange = (e: UploadChangeParam<UploadFile<any>>, q: ISurveyQuestion) => {
    const fileName = e.file.name; // Assuming you're only dealing with single file uploads
    if (!fileName) {
      console.error('No file uploaded');
      return;
    }
    const tempResponse = [...responseData];
    const index = tempResponse.findIndex((x) => x.questionId === q.id);
    if (index !== -1) {
      tempResponse[index] = {
        ...tempResponse[index],
        questionId: q.id,
        value: fileName,
      };
    }
    setFileList([...fileList, e.file]);
    setResponseData(tempResponse);
  };

  React.useEffect(() => {
    setSelectedOpeningMsg(survey?.openingMessage || null);
    console.log('selectedClosingMsg', survey?.openingMessage);
    setSelectedClosingMsg(survey?.closingMessage || null);
  }, [survey]);

  const onGeneralChange = (e:any, q:ISurveyQuestion) => {
    const tempResponse = [...responseData];
    const responseArr = [];
    responseArr.push(e.target.value);
    const index = tempResponse.findIndex((x) => x.questionId === q.id);
    tempResponse[index] = {
      questionId: q.id,
      value: responseArr,
    };
    // console.log(tempResponse)
    setResponseData(tempResponse);
  };

  const renderElement = (q: ISurveyQuestion) => {
    if (q.questionTypeId === SurveyQuestionTypeId.Text) {
      return <TextArea key={textKey} onChange={(e) => onGeneralChange(e, q)} rows={3} />;
    }
    if (q.questionTypeId === SurveyQuestionTypeId.MultipleChoice) {
      return (
        <Radio.Group onChange={(e) => onRadioChange(e, q)} className="multi-choice">
          {q.options?.map((m:any, inx:any) => (
            // eslint-disable-next-line react/no-array-index-key
            <Radio key={inx} value={m}>{m}</Radio>
          ))}
        </Radio.Group>
      );
    }
    if (q.questionTypeId === SurveyQuestionTypeId.MultiSelect) {
      return (
        <Select key={selectKey} mode="tags" size="large" onChange={(e) => onSelectChange(e, q)} style={{ width: '100%', height: '60px' }} placeholder="Select an item or type something new and press enter">
          {q.options?.map((option: string, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Select.Option key={index} value={option}>{option}</Select.Option>
          ))}
        </Select>
      );
    }
    if (q.questionTypeId === SurveyQuestionTypeId.Document) {
      return (
        <>
          <Upload
            key={q.id}
            name="file"
            action="/upload.do"
            onChange={(info) => onUploadChange(info, q)}
            multiple
            style={{ width: '100%', height: '60px' }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </>
      );
    }
    if (q.questionTypeId === SurveyQuestionTypeId.Email) {
      return <TextArea key={textKey} onChange={(e) => onGeneralChange(e, q)} rows={1} />;
    }
    if (q.questionTypeId === SurveyQuestionTypeId.Number) {
      return <TextArea key={textKey} onChange={(e) => onGeneralChange(e, q)} rows={3} />;
    }

    return <span />;
  };

  return (
    <Drawer
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      width="100%"
      className="review-drawer"
      closeIcon={<AiFillCloseCircle style={{ fontSize: '4.2rem', color: '#D8232A' }} />}
    >
      <Spin tip={loadingText} spinning={loading}>
        <div className="review-drawer-root">
          {survey
                            && (
                            <>
                              {survey.surveyQuestions.length > 0
                                ? (
                                  <div className="survey-droppable-area survey-response-area">
                                    {reviewsList.length >= 0
                                      ? (
                                        <>
                                          <div key={-200} className="welcome-closing-messsage">
                                            <span className="opening-msg">
                                              {selectedOpeningMsg}
                                            </span>
                                          </div>
                                          {survey.surveyQuestions.map((q, i) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <div key={i} className="question">
                                              <div className="question-top">
                                                <span className="question-title">{(q.text ?? '')}</span>
                                                <span className="question-controls" />
                                              </div>
                                              <div className="question-element">
                                                {renderElement(q)}
                                              </div>
                                            </div>
                                          ))}
                                          <div key={-201} className="welcome-closing-messsage">
                                            <span className="opening-msg">
                                              {selectedClosingMsg}
                                            </span>
                                          </div>
                                          <Button disabled={isPosting} onClick={() => respondToQuestion()} className="next-survey-btn">Submit</Button>
                                        </>
                                      )
                                      : (
                                        <div className="survey-completed-view">
                                          <span className="thanks-text">You have completed all your surveys!</span>
                                          <img className="completed-img" alt="" src={completedSurvey} />
                                        </div>
                                      )}
                                  </div>
                                )
                                : (
                                  <>
                                  </>
                                )}
                            </>
                            )}
        </div>
      </Spin>
    </Drawer>
  );
});
export default ReviewDrawer;
